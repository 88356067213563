import React, { HTMLAttributes, useState } from "react";
import addToMailchimp, { AddResult } from "gatsby-plugin-mailchimp";
import styled from "styled-components";
import { onDesktop } from "../../shared/layout/Breakpoints";

export const ID = "artShare";

const MailingListForm = styled(function(
  props: Omit<HTMLAttributes<HTMLFormElement>, "children" | "onSubmit">
) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<AddResult | null>(null);
  const [recipient, setRecipient] = useState("");
  return (
    <form
      {...props}
      onSubmit={async e => {
        e.preventDefault();
        setIsLoading(true);
        try {
          const result = await addToMailchimp(recipient, {});
          setResult(result);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      <h3 id={ID}>Join my Art Share</h3>
      <p>
        Follow my sketches and interetsing things I learn on my journey of
        illustration and science. Sign up for my newsletter to receive monthly
        updates.
      </p>
      <Group>
        <label htmlFor="mailingListRecipient">E-mail address</label>
        <input
          id="mailingListRecipient"
          type="email"
          placeholder="yourname@domain.com"
          value={recipient}
          required
          onChange={e => setRecipient(e.target.value)}
        />
        {isLoading ? (
          <span>Sending...</span>
        ) : result?.result === "success" ? (
          <span>Thank you!</span>
        ) : (
          <button type="submit">Sign Up</button>
        )}
      </Group>
    </form>
  );
})`
  border: 1px solid black;
  padding: 1em;
  button {
    background: none;
    border: 1px solid grey;
    background: black;
    color: white;
    padding: 0.1em 1em;
    cursor: pointer;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 0.5em;
  }

  ${onDesktop`
    flex-direction: row;
    & > * {
      margin-bottom: 0;
      margin-right: 1em;
    }
  `}
`;

export default MailingListForm;
