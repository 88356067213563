import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { onDesktop } from "../shared/layout/Breakpoints";
import Header from "./Header";
import "./layout.css";
import MailingListForm from "./social/MailingListForm";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Page = styled.main`
  flex: 1;
  padding: 0px 1.0875rem 1.45rem;
`;

const Footer = styled.footer`
  background: black;
  padding: 1em;
  &,
  a {
    color: white;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${onDesktop`
    flex-direction: row;
  `}

  ${Content} {
    flex: 1;
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Content>
        <Page>{children}</Page>
        <Footer>
          <MailingListForm />
          <p>Copyright &copy; {new Date().getFullYear()} Laura Valencia</p>
        </Footer>
      </Content>
    </Wrapper>
  );
};

export default Layout;
