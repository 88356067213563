import React from "react";
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const InstagramIcon = () => {
  const data = useStaticQuery(graphql`
    query {
      instagram: file(relativePath: { eq: "social/instagram-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <SocialIconImg
      alt="Inkplasma on Instagram"
      fluid={data.instagram.childImageSharp.fluid}
    />
  );
};

export const SocialIconImg = styled(Img)`
  width: 40px;
  height: auto;
  display: inline-block;
`;

export default InstagramIcon;
