import React from "react";
import styled from "styled-components";
import InstagramIcon from "../components/social/InstagramIcon";

const SocialList = styled.ul`
  list-style-type: none;
  margin: 0;
  display: flex;
`;

const SocialItem = styled.li`
  padding: 0;
`;

const SocialLink = styled.a`
  text-align: center;
`;

export default function Social() {
  return (
    <SocialList>
      <SocialItem>
        <SocialLink
          href="https://instagram.com/inkplasma"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </SocialLink>
      </SocialItem>
    </SocialList>
  );
}
