import { Link, GatsbyLinkProps } from "gatsby";
import React, { useState, ButtonHTMLAttributes, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { onDesktop } from "../shared/layout/Breakpoints";
import { useLocation } from "@reach/router";
import Social from "./Social";

const Banner = styled.header`
  background: black;

  &,
  a {
    color: white;
  }

  a {
    transition: color 0.2s ease;

    &:hover {
      color: tomato;
    }
  }

  p {
    margin: 0;
  }

  margin-bottom: 1.45em;
  padding: 1em;

  hr {
    margin-top: 1em;
    display: none;
    border: none;
    border-bottom: 1px solid white;
  }

  ${onDesktop`
    margin-bottom: 0;
    
    hr {
      display: block;
    }
  `}
`;

const Heading = styled.h1`
  margin: 0;
`;

const HeaderLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const pages = [
  {
    title: "Illustration",
    link: "/",
  },
  {
    title: "Concept Art",
    link: "/concept-art",
  },
  {
    title: "Ink",
    link: "/ink",
  },
  {
    title: "Bio & Contact",
    link: "/bio",
  },
  {
    title: "Pixel Art",
    link: "/pixel-art",
  }
];

const MenuList = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;

  margin-top: 1em;
`;

interface MenuWrapperProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
}

const MenuWrapper = styled(({ open, ...props }: MenuWrapperProps) => (
  <div {...props} />
))`
  ${props =>
    !props.open &&
    css`
      display: none;

      ${onDesktop`
        display:block;
      `}
    `}
`;

interface MenuLinkProps<TState> extends Omit<GatsbyLinkProps<TState>, "ref"> {
  isActive: boolean;
}

const MenuLink = styled(function<TState>({
  isActive,
  ...props
}: MenuLinkProps<TState>) {
  return <Link<TState> {...props} />;
})`
  transition: color 0.2s ease;
  font-size: 1.2em;

  & {
    text-decoration: none;
  }

  ${props =>
    props.isActive &&
    css`
      &:not(:hover) {
        color: tomato;
      }
    `}
`;

function Menu({ open }: { open: boolean }) {
  const location = useLocation();
  return (
    <MenuWrapper open={open}>
      <MenuList>
        {pages.map(({ title, link }) => (
          <li key={link}>
            <MenuLink to={link} isActive={location.pathname === link}>
              {title}
            </MenuLink>
          </li>
        ))}
      </MenuList>
      <hr />
      <a href="mailto:post@inkplasma.com">post@inkplasma.com</a>
      <br />
      <br />
      <Social />
    </MenuWrapper>
  );
}

interface MenuButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  open: boolean;
}

const MenuButton = styled(({ open, ...props }: MenuButtonProps) => (
  <button {...props}>
    <span />
    <span />
    <span />
  </button>
))`
  cursor: pointer;
  background: none;
  border: none;

  ${onDesktop`
    display: none;
  `}

  span {
    display: block;
    width: 30px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: white;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.1s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.1s cubic-bezier(0.77, 0.2, 0.05, 1);
    transform-origin: 50% 50%;
  }

  ${props =>
    props.open &&
    css`
      span {
        opacity: 1;
        background: tomato;
      }
      span:first-child {
        transform: translateY(9px) rotate(135deg);
      }
      span:nth-child(2) {
        opacity: 0;
      }
      span:last-child {
        transform: translateY(-9px) rotate(-135deg);
      }
    `}
`;

const HeaderRow = styled.div`
  display: flex;
`;

const Title = styled.div`
  flex: 1;
`;

export default function Header({ siteTitle }: { siteTitle: string }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <Banner>
      <HeaderRow>
        <Title>
          <Heading>
            <HeaderLink to="/bio">{siteTitle}</HeaderLink>
          </Heading>
          <p>Laura Valencia</p>
        </Title>
        <MenuButton open={isMenuOpen} onClick={() => setIsMenuOpen(x => !x)} />
      </HeaderRow>
      <hr />
      <Menu open={isMenuOpen} />
    </Banner>
  );
}
