import { css, CSSObject, SimpleInterpolation } from "styled-components";

export const MIN_DESKTOP_WIDTH = 720;
export const MIN_LARGE_DESKTOP_WIDTH = 1280;

export const onDesktop = (
  first: CSSObject | TemplateStringsArray,
  ...interpolations: SimpleInterpolation[]
) =>
  css`
    @media screen and (min-width: ${MIN_DESKTOP_WIDTH}px) {
      ${css(first, ...interpolations)}
    }
  `;

export const onLargeDesktop = (
  first: CSSObject | TemplateStringsArray,
  ...interpolations: SimpleInterpolation[]
) =>
  css`
    @media screen and (min-width: ${MIN_LARGE_DESKTOP_WIDTH}px) {
      ${css(first, ...interpolations)}
    }
  `;